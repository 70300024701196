import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getRecap } from '../Services/Recuperation/Recuperation';

export default function Recuperation() {
    const [recap, setRecap] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
           
          try {
         
             const response = await getRecap();
  
             setRecap(response.data);
          
          } catch (error) {
            console.error('Error fetching Poutre data:', error);
          }
        };
    
        fetchData();
      }, []);
  return (
     <div className='pageAffichage'>

<div className="header">
  <div  className = "iconright">

    <img src="/image/logo_FTG.jpg" className="mT" width="150" height="150" alt="Logo" />

  </div>
  <div className='center'>
  <div className="Titre1">
    <h3>البطولة الوطنية للجمباز الفني إناث  </h3> 
    <h3> 2023-2022 نهائيات الفرق والفردي العام موسم </h3>
  </div>
  <div className='icon'>
  <img src="/image/logo_FTG.jpg" className="logo-image mT"  alt="Logo" />

  </div>
  
  </div>
  <div  className = "iconleft">
  <img src="/image/Tunisia-Flag-Transparent-Background.png" width="250" height="200" className="mT" alt="Logo" />
</div>
  </div>
 

<div >

       <table className="table">
       <thead className="thead">
         <tr className="tr">
         <th  className="th"><h5> Athlelte </h5></th>
         <th  className="th"><h5> Grade</h5></th>
         
           <th  className="th"><h5> Equipe</h5></th>
           <th className="th"> <h5>Total Sol</h5></th>
           <th className="th"><h5> Total Bar</h5></th>
           <th className="th"><h5> Total Poutre</h5></th>
           <th className="th"><h5> Total Table</h5></th>

         </tr>
       </thead>
       
       <tbody>
       {recap.map((item) => (
           <tr className="tr">
           <td className="tm-product-name td " >{item.nom}</td>
           <td className="tm-product-name td " >{item.grade}</td>
            <td className="tm-product-name td " ><h2>{item.equipe}</h2></td>
             <td className="tm-product-name td "><h2>{item.total_sol}</h2></td>
             <td className="tm-product-name td "><h2>{item.total_bar}</h2></td>
             <td className="tm-product-name td "><h2>{item.total_poutre}</h2></td>
             <td className="tm-product-name td "><h2>{item.total_table}</h2></td>
           </tr>
          
          ))}
       </tbody>    
      

     </table>
    
    
    

       
</div>

    </div>
  )
}
