import axios from "axios";
/**
 * Authentification Web Service 
 * @returns 
 */

export async function GetAthletes() { 
     try {
         const response = await axios.get(`https://api.competition.cleges.com/api/v1/competitions/getAthletes`)
         return response;
 
     }catch(error){
         return error?.response
     }
   }   
/**
 * Authentification Web Service 
 * @returns 
 */

export async function GetAthletesparcode(code) { 
     try {
         const response = await axios.get(`https://api.competition.cleges.com/api/v1/competitions/getAthletes/${code}`)
         return response;
 
     }catch(error){
         return error?.response
     }
   }  