import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { GetAthletes } from '../Services/Athletes/Athletes';
import { GetAthletesparcode } from '../Services/Athletes/Athletes';
export default function Athletes() {
  const navigate = useNavigate();

    const [athlete, setathlete] = useState([]);
    let { code } = useParams();

    useEffect(() => {
      const fetchData = async () => {
        try {
          let response;
          if (code === "undefined") {
            
            response = await GetAthletes();
          }
          else{
            response = await GetAthletesparcode(code);

          }
          setathlete(response.data);
        } catch (error) {
          console.error('Error fetching Poutre data:', error);
        }
      };
  
      fetchData();
    }, [code]);
  return (
    <div>
    
    <nav className="navbar navbar-expand-xl">
      <div className="container h-100">
        <a className="navbar-brand" href="index.html">
          <h1 className="tm-site-title mb-0" onClick={() => navigate('/login')}>Compétition</h1>
        </a>
        <button className="navbar-toggler ml-auto mr-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars tm-nav-icon" />
        </button>
      </div>
    </nav>
    <div className="container mt-5">
    <a className="navbar-brand" href="index.html">
          <h1 className="tm-site-title mb-0" >Rotation </h1>{code !== "undefined"?( code) :null}
        </a>
        <br/><br/>
              <table className="table table-hover tm-product-table">
                <thead>
                <tr>
                <th>ID</th>
                  <th> Code</th>
                  <th>Nom</th>
                  <th>Equipe Code</th>
                  <th>Grade</th>
                </tr>
                </thead>
                <tbody>
                  {athlete.map((item) => (
                  <tr key={item.id}>
                  <td className="tm-product-name">{item.id}</td>
                  <td className="tm-product-name">{item.code}</td>
                  <td className="tm-product-name">{item.nom}</td>
                  <td className="tm-product-name">{item.equipe}</td>
                  <td className="tm-product-name">{item.grade}</td>
                 
              </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* table container */}
           
          </div>
  )
}
