import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Rotation from "./Rotation";
import { GetRotation } from "../Services/Rotation/RotationApi";
export default function Mainpage() {

  //const [code, setcode] = useState("");
  const [codeA, setcodeA] = useState("");
  const [role, setrole] = useState("");
  const [routation, setroutation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [poutreurl,setpoutreurl] = useState('https://competition.cleges.com/poutre');
  const [windowRef, setWindowRef] = useState(null);
  let { code } = useParams();
    //const history = useHistory();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const responserotation = await GetRotation();
        const uniqueData = responserotation.data.filter((item, index, array) => {
          return array.findIndex((elem) => elem.code === item.code) === index;
        });
        
        setroutation(uniqueData);
        setIsLoading(false);
      
      } catch (error) {
        console.error('Error fetching rotation data:', error);
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
 
    const login = Cookies.get("token");
    if (login) {
      const role_cookies = Cookies.get("role");
      setrole(role_cookies);
    }
  
  }, []);
  const handelnavigateTo = (type) => {
   
  if (windowRef && !windowRef.closed) {
        
      windowRef.focus();
    } else {
      
      const newWindow = window.open(`${poutreurl}/${type}`, '_blank');
      setWindowRef(newWindow);
    }
  };

  const GetAthletes = () => {
    //navigate(`/Athletes/${codeA}`);
  };
  const handleInputChangeA = (event) => {
    setcodeA(event.target.value);
    window.location.replace(`/MainPage/${code}`);

  };
  const handleInputChange = (event) => {
    
   code=event.target.value;
      window.location.replace(`/MainPage/${code}`);

  };
  const navigateadmin =()=>{
    if (windowRef && !windowRef.closed) {
        
      windowRef.focus();
    } else {
      
      const newWindow = window.open(`/recup`, '_blank');
      setWindowRef(newWindow);
    }
  }
  return (
    <div>
    <div className id="home">
      <nav className="navbar navbar-expand-xl">
        <div className="container h-100">
          <a className="navbar-brand" href="index.html">
            <h1 className="tm-site-title mb-0">Compétition</h1>
          </a>
          <button
            className="navbar-toggler ml-auto mr-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars tm-nav-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto h-100">
              {role === "admin" ||
              role === "bar" ||
              role === "table" ||
              role === "poutre" ||
              role === "sol" ? (
                <>
                  {role === "admin" || role === "table" ? (
                    <li className="nav-item">
                      <a
                        className="btn btn-primary btn-block text-uppercase nav "
                        onClick={() => handelnavigateTo("table")}
                      >
                        Table
                      </a>
                    </li>
                  ) : null}
                  {role === "admin" || role === "poutre" ? (
                    <li className="nav-item">
                      <a
                        className="btn btn-primary btn-block text-uppercase nav"
                        onClick={() => handelnavigateTo("poutre")}
                      >
                        Poutre
                      </a>
                    </li>
                  ) : null}
                  {role === "admin" || role === "sol" ? (
                    <li className="nav-item">
                      <a
                        className="btn btn-primary btn-block text-uppercase nav"
                        onClick={() => handelnavigateTo("sol")}
                      >
                        Sol
                      </a>
                    </li>
                  ) : null}
                  {role === "admin" || role === "bar" ? (
                    <li className="nav-item">
                      <a
                        className="btn btn-primary btn-block text-uppercase nav"
                        onClick={() => handelnavigateTo("bar")}
                      >
                        Bar
                      </a>
                    </li>
                  ) : null}
                   {role === "admin" ? (
                    <li className="nav-item">
                      <a
                        className="btn btn-primary btn-block text-uppercase nav"
                        onClick={navigateadmin}
                      >
                       Récupération
                      </a>
                    </li>
                  ) : null}
                </>
              ) : null}
            </ul>
          <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link d-block" >
              <b onClick={()=>{ Cookies.remove('token');Cookies.remove('role');Cookies.remove('usercode');  window.location.replace(`/`);}}>Déconnexion</b>
            </a>
          </li>
        </ul>

          </div>
        </div>
      </nav>
   </div>
   <div className="icon3 "> <img src="/image/logo_FTG.jpg" className="logo-Pfix" width="200" height="200" alt="Logo" /></div>
      <div className="icon4 ">      {role === "sol"?(
      <img src="/image/sol.jpg" className="logo-P" width="200" height="200" alt="Logo" />
  ):null}
      {role === "poutre"?(
      <img src="/image/poutre.jpg" className="logo-P" width= "200" height="200" alt="Logo" />
  ):null}
     {role === "table"?(
      <img src="/image/table.jpg" className="logo-P" width="200" height="200" alt="Logo" />
  ):null}
       {role === "bar"?(
      <img src="/image/bar.jpg" className="logo-P" width="200" height="200" alt="Logo" />
  ):null}
        {role === "admin"?(
      <img src="/image/Tunisia-Flag-Transparent-Background.png" className="logo-P" width="200" height="200" alt="Logo" />
  ):null}

  </div>
      <div className="container mt-5">
        <div className="row tm-content-row">
          <div className="col-12 tm-block-col">
            <div className="tm-bg-primary-dark tm-block tm-block-h-auto hidden-div">
              <h2 className="tm-block-title">Athlétes</h2>
              <input
                value={codeA}
                onChange={handleInputChangeA}
                className="form-control validate"
              />
              <button
                onClick={GetAthletes}
                className="btn btn-primary btn-block text-uppercase"
              >
                Afficher l'Athlétes
              </button>
              <br />
              <br />
              <button
           
                className="btn btn-primary btn-block text-uppercase"
              >
                Afficher tous les Athlétes
              </button>
            </div>
          </div>
        </div>
       
        <div className="row tm-content-row"   >
          <div className="col-12 tm-block-col">
            <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
              <h2 className="tm-block-title">Rotation</h2>
          
           <div>
    {/* Render loading state or other content while data is being fetched */}
    {isLoading ? (
      <p>Loading...</p>
    ) : (
      <select
      style={{ height: "70px" }}
        value={code}
        onChange={handleInputChange}
        className="form-control validate select"
      >
        <option value="">Select</option>
        {routation.map((item) => (
          <option value={item.code}>{item.code}</option>
        ))}
      </select>
    )}
    {/* Render other content */}
  </div>
            </div>
          </div>
        </div>
      </div>
        {/* row */}
        <div>
          
          <h2 className="tm-block-title">Formulaire</h2>
          <Rotation code={code} />
        </div>
      </div>

  
  );
}
