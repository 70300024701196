import React, { useEffect, useState } from 'react';
import { GetAffiche } from '../Services/GetAffiche/GetAffiche';
import Cookies from 'js-cookie';

export default function Affichage() {
    const [poutre, setPoutre] = useState([]);

    const [role, setrole] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const role = Cookies.get('role'); 
         setrole(role);
          try {
         
             const response = await GetAffiche(role);
  
            setPoutre(response.data);
          
          } catch (error) {
            console.error('Error fetching Poutre data:', error);
          }
        };
    
        fetchData();
      }, []);
    
  return (
    <div className='pageAffichage'>

<div className="header">
  <div  className = "iconright">

    <img src="/image/logo_FTG.jpg" className="mT" width="150" height="150" alt="Logo" />

  </div>
  <div className='center'>
  <div className="Titre1">
    <h3>البطولة الوطنية للجمباز الفني إناث  </h3> 
    <h3> 2023-2022 نهائيات الفرق والفردي العام موسم </h3>
  </div>
  <div className='icon'>
  {role === "sol" ? (
    <img src="/image/sol.jpg" className="logo-image mT"  alt="Logo" />
  ) : null}
  {role === "poutre" ? (
    <img src="/image/poutre.jpg" className="logo-image mT" alt="Logo" />
  ) : null}
  {role === "table" ? (
    <img src="/image/table.jpg" className="logo-image mT" alt="Logo" />
  ) : null}
  {role === "bar" ? (
    <img src="/image/bar.jpg" className="logo-image mT" alt="Logo" />
  ) : null}
  </div>
  
  </div>
  <div  className = "iconleft">
  <img src="/image/Tunisia-Flag-Transparent-Background.png" width="250" height="200" className="mT" alt="Logo" />
</div>
  </div>
 

<div >
{role !== "table"?(
       <table className="table">
       <thead className="thead">
         <tr className="tr">
         <th  className="th"><h5> Athlelte </h5></th>
         <th  className="th"><h5> Equipe</h5></th>
         <th  className="th"> </th>
           <th  className="th"><h5> Note E</h5></th>
           <th className="th"> <h5>Note D</h5></th>
           <th className="th"><h5> Pénalite</h5></th>
           <th className="th"><h5> Note Finale</h5></th>
         </tr>
       </thead>
       
       <tbody>
       {role !== "table" && poutre.map((item) => (
           <tr className="tr">
           <td className="tm-product-name td " >{item.user}</td>
           <td className="tm-product-name td " >{item.equipe}</td>
           <td className="tm-product-name td " > <img src={item.logo} alt="Logo" className="logo-image" /></td>
            <td className="tm-product-name td " ><h2>{item.note_e}</h2></td>
             <td className="tm-product-name td "><h2>{item.note_d}</h2></td>
             <td className="tm-product-name td "><h2>{item.penalite}</h2></td>
             <td className="tm-product-name td "><h2>{item.score_finale}</h2></td>
           </tr>
          
          ))}
       </tbody>    
      

     </table>
      ):null}
    
    
{role === "table"?(
     <table className="table">
      
        <thead className="thead">
          <tr className="tr">
          <th  className="th"><h5> Athlelte</h5></th>
         <th  className="th"><h5> Equipe</h5></th>
         <th  className="th">  </th>
         <th  className="th"> </th>
            <th  className="th"><h5> Note E</h5></th>
            <th className="th"><h5> Note D</h5></th>
            <th className="th"><h5> Pénalite</h5></th>
            <th className="th"><h5> Note Final</h5></th>
          </tr>
        </thead>
         
        {role === "table" && (
  <tbody>
    {poutre.map((item, index) => (
      <React.Fragment key={item.id}>
        <tr className="tr">
          <td className="tm-product-name td" >{item.user}</td>
          <td className="tm-product-name td">{item.equipe}</td>
          <td className="tm-product-name td">
            <img src={item.logo} alt="Logo" className="logo-image" />
          </td>
          <td className="tm-product-name td">
            <h2>S1</h2>
          </td>
          <td className="tm-product-name td">
            <h2>{item.s1_note_e}</h2>
          </td>
          <td className="tm-product-name td">
            <h2>{item.s1_note_d}</h2>
          </td>
          <td className="tm-product-name td" >
            <h2>{item.s1_penalite}</h2>
          </td>
          <td className="tm-product-name td" rowSpan="2">
            <h2>{item.score_finale}</h2>
          </td>
        </tr>
        <tr className="tr">
        <td className="tm-product-name td">{item.user}</td>
          <td className="tm-product-name td">{item.equipe}</td>
          <td className="tm-product-name td">
            <img src={item.logo} alt="Logo" className="logo-image" />
          </td>
          <td className="tm-product-name td">
            <h2>S2</h2>
          </td>
          <td className="tm-product-name td">
            <h2>{item.s2_note_e}</h2>
          </td>
          <td className="tm-product-name td">
            <h2>{item.s2_note_d}</h2>
          </td>
          <td className="tm-product-name td">
            <h2>{item.s2_penalite}</h2>
          </td>
        </tr>
        {index < poutre.length - 1 && <tr className="item-separator"></tr>}
      </React.Fragment>
    ))}
  </tbody>
)}



  </table> 
 ):null}
       
</div>

    </div>
  );
}
