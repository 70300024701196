import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Formulaire from "./Pages/Formulaire";
import Poutre from "./Pages/Poutre";
import Mainpage from "./Pages/Mainpage";
import Rotation from "./Pages/Rotation";
import Athletes from "./Pages/Athletes";
import Affichage from "./Pages/Affichage";
import Recuperation from "./Pages/Recuperation.jsx";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
        <Route path="/MainPage/:code" element={<Mainpage />} />
        <Route path="/recup" element={<Recuperation />} />
          <Route path="/" element={<Login />} />
          <Route path="/formulaire" element={<Formulaire />} />
          <Route path="/poutre/:type" element={<Poutre />} />
          <Route path="/rotation/:code" element={<Rotation />} />
          <Route path="/affiche/:code" element={<Affichage />} />

          <Route path="/Athletes/:code" element={<Athletes />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
