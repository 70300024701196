import axios from "axios";
/**
 * Authentification Web Service 
 * @returns 
 */

export async function GetRotation() { 
     try {
         const response = await axios.get(`https://api.competition.cleges.com/api/v1/competitions/getDistinctRotations`)
         return response;
 
     }catch(error){
         return error?.response
     }
   }   
/**
 * Authentification Web Service 
 * @returns 
 */

export async function GetRotationparcode(code,type) { 
     try {
         const response = await axios.get(`https://api.competition.cleges.com/api/v1/competitions/getRotations/${code}/${type}`)
         return response;
 
     }catch(error){
         return error?.response
     }
   }  

