

import axios from "axios";

export async function getResultat(type,code) { 

     try {
         const response = await axios.get(`https://api.competition.cleges.com/api/v1/competitions/getResultat/${type}/${code}`)
         return response;
 
     }catch(error){
         return error?.response
     }
   } 

