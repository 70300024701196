import React, { useEffect, useRef , useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GetRotation } from "../Services/Rotation/RotationApi";
import { GetRotationparcode } from "../Services/Rotation/RotationApi";
import { formulaire,formulaireTable } from "../Services/Formulaire/FormulaireApi";
import { toast, ToastContainer } from "react-toastify";
import { getResultat } from "../Services/Resultat/ResultatApi";
import Cookies from "js-cookie";

export default function Rotation({ code }) {
  const [routation, setroutation] = useState([]);
  const [resultat, setresultat] = useState([]);
  const tableRef = useRef(null);  
  const [windowRef, setWindowRef] = useState(null);
  const [role, setrole] = useState("");
  const type = Cookies.get("role");
  const [resetTable, setResetTable] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
       
          const response = await GetRotationparcode(code, type);
         
          
          const resultat = await getResultat(type, code);
          setresultat(resultat.data);
        
          const updatedRotation = response.data.map((item) => {
            const existsInResultat = resultat.data.some((resultatItem) => {
              return item.user_code === resultatItem.user_code;
            });
          
            const res = existsInResultat ? resultat.data.filter((resultatItem) => resultatItem.user_code === item.user_code) : [];
          
            return {
              ...item,
              inresultat: existsInResultat ? 1 : 0,
              res: res,
            };
          });
          
          setroutation(updatedRotation);
          console.log(routation);
        
      } catch (error) {
        console.error("Error fetching routation data:", error);
      }
    };
  
    fetchData();
    const role = Cookies.get("role");
    setrole(role);
  }, [code]);
  

  useEffect(() => {
    
    const handleKeyPress = (event) => {
      const activeElement = document.activeElement;
      if (event.key === "ArrowLeft") {
        // Déplacer le focus vers le champ précédent
        const previousCell = findPreviousEditableCell(activeElement.parentElement);
        if (previousCell) {
          previousCell.querySelector("input").focus();
        }
      } else if (event.key === "ArrowRight") {
        // Déplacer le focus vers le champ suivant
        const nextCell = findNextEditableCell(activeElement.parentElement);
        if (nextCell) {
          nextCell.querySelector("input").focus();
        }
      }
    };

    const findPreviousEditableCell = (currentCell) => {
      let previousCell = currentCell.previousElementSibling;
      while (previousCell) {
        const input = previousCell.querySelector("input");
        if (input && !input.readOnly) {
          return previousCell;
        }
        previousCell = previousCell.previousElementSibling;
      }
      return null;
    };

    const findNextEditableCell = (currentCell) => {
      let nextCell = currentCell.nextElementSibling;
      while (nextCell) {
        const input = nextCell.querySelector("input");
        if (input && !input.readOnly) {
          return nextCell;
        }
        nextCell = nextCell.nextElementSibling;
      }
      return null;
    };

    tableRef.current.addEventListener("keydown", handleKeyPress);

    return () => {
      tableRef.current.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleSubmit = async (i) => {
    const updatedRoutation = [...routation];
  updatedRoutation[i].submitting = true;
  setroutation(updatedRoutation);
    if (role !== "table") {

    const  datasub = {
        user_code: ObjectCompetition[i].user_code,
        type: role,
        rotation_code: code,
        note_e1: ObjectCompetition[i].note_e1,
        note_e2: ObjectCompetition[i].note_e2,
        note_e3: ObjectCompetition[i].note_e3,
        note_e4: ObjectCompetition[i].note_e4,
        note_e: ObjectCompetition[i].note_e,
        note_d: ObjectCompetition[i].note_d,
        penalite: ObjectCompetition[i].penalite,
        score_finale: ObjectCompetition[i].score_Finale,
      };
      console.log(datasub);
   const repformulaire = await formulaire(datasub);
   console.log(repformulaire.data.message);
    if (repformulaire?.status === 200) {
      if (windowRef && !windowRef.closed) {
        
        windowRef.focus();
      } else {
        
        const newWindow = window.open(`https://competition.cleges.com/affiche/${role}`, '_blank');
        setWindowRef(newWindow);
      }
      updatedRoutation[i].submitting = false;
  setroutation(updatedRoutation);
    } else {
      toast(repformulaire.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  else if(role === "table"){
    const datasubtable = { 
      user_code: ObjectCompetitiontable[i].user_code,
      rotation_code: code,
      s1_note_e1: ObjectCompetitiontable[i].s1_note_e1,
      s1_note_e2: ObjectCompetitiontable[i].s1_note_e2,
      s1_note_e3: ObjectCompetitiontable[i].s1_note_e3,
      s1_note_e4: ObjectCompetitiontable[i].s1_note_e4,
      s1_note_e: ObjectCompetitiontable[i].s1_note_e,
      s1_note_d: ObjectCompetitiontable[i].s1_note_d,
      s1_penalite: ObjectCompetitiontable[i].s1_penalite,
      s1: ObjectCompetitiontable[i].s1,
      s2_note_e1: ObjectCompetitiontable[i].s2_note_e1,
      s2_note_e2: ObjectCompetitiontable[i].s2_note_e2,
      s2_note_e3: ObjectCompetitiontable[i].s2_note_e3,
      s2_note_e4: ObjectCompetitiontable[i].s2_note_e4,
      s2_note_e: ObjectCompetitiontable[i].s2_note_e,
      s2_note_d: ObjectCompetitiontable[i].s2_note_d,
      s2_penalite: ObjectCompetitiontable[i].s2_penalite,
      s2: ObjectCompetitiontable[i].s2,
      s2: ObjectCompetitiontable[i].score_finale,
      //score_finale: (ObjectCompetitiontable[i].s2 +ObjectCompetitiontable[i].s1)/2,

    };
    console.log(datasubtable);
  const Tableformulaire = await formulaireTable(datasubtable);
  console.log(Tableformulaire.data);
  if (Tableformulaire?.status === 200) {
    if (windowRef && !windowRef.closed) {
        
      windowRef.focus();
    } else {
      
      const newWindow = window.open(`https://competition.cleges.com/affiche/${role}`, '_blank');
      setWindowRef(newWindow);
    }
    updatedRoutation[i].submitting = false;
    setroutation(updatedRoutation);
  } else {
    toast(Tableformulaire.data.message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  }
  };
  const [ObjectCompetition, setObjectCompetition] = useState({});
  const [ObjectCompetitiontable, setObjectCompetitiontable] = useState({});
 
  const calculateNoteE = (i, name, value) => {
    const role = Cookies.get("role");
    var note_e1 = routation[i].note_e1 ? routation[i].note_e1 : 0;
    var note_e2 = routation[i].note_e2 ? routation[i].note_e2 : 0;
    var note_e3 = routation[i].note_e3 ? routation[i].note_e3 : 0;
    var note_e4 = routation[i].note_e4 ? routation[i].note_e4 : 0;
     if(name=="note_e1"){
      note_e1=value;
    }
    else if(name=="note_e2"){
      note_e2=value;
    }
    else if(name=="note_e3"){
      note_e3=value;
    }
    else  if(name=="note_e4"){
      note_e4=value;
    }
    const sum =
      Number(note_e1) + Number(note_e2) + Number(note_e3) + Number(note_e4);
    const max = Math.max(
      Number(note_e1),
      Number(note_e2),
      Number(note_e3),
      Number(note_e4)
    );
    const min = Math.min(
      Number(note_e1),
      Number(note_e2),
      Number(note_e3),
      Number(note_e4)
    );
    routation[i][name] = value;
    routation[i]["note_e"] = ((sum - max - min) / 2).toFixed(2);;
    routation[i]["role"] = role;
    setObjectCompetition({ ...ObjectCompetition, [i]: routation[i] });
    //console.log(ObjectCompetition);
  };
  const calculatescorefinale = (i, name, value) => {
    var noteE = routation[i].note_e ? routation[i].note_e : 0;
    var noteD = routation[i].note_d ? routation[i].note_d : 0;
    var penalite = routation[i].penalite ? routation[i].penalite : 0;
    if(name=="note_e"){
      noteE=value;
    }
    else if(name=="note_d"){
      noteD=value;
    }
    else if(name=="penalite"){
      penalite=value;
    }
    routation[i][name] = value;
    routation[i]["score_Finale"] =
      (Number(noteE) + Number(noteD) - Number(penalite)) ;
    setObjectCompetition({ ...ObjectCompetition, [i]: routation[i] });
    
  };
  const calculateNoteE2 = (i, name, value) => {
    var s1_note_e1 = routation[i].s1_note_e1 ? routation[i].s1_note_e1 : 0;
    var s1_note_e2 = routation[i].s1_note_e2 ? routation[i].s1_note_e2 : 0;
    var s1_note_e3 = routation[i].s1_note_e3 ? routation[i].s1_note_e3 : 0;
    var s1_note_e4 = routation[i].s1_note_e4 ? routation[i].s1_note_e4 : 0;

    if(name=="s1_note_e1"){
      s1_note_e1=value;
    }
    else if(name=="s1_note_e2"){
      s1_note_e2=value;
    }
    else if(name=="s1_note_e3"){
      s1_note_e3=value;
    }
    else  if(name=="s1_note_e4"){
      s1_note_e4=value;
    }

    const sum =
      Number(s1_note_e1) +
      Number(s1_note_e2) +
      Number(s1_note_e3) +
      Number(s1_note_e4);
    const max = Math.max(
      Number(s1_note_e1),
      Number(s1_note_e2),
      Number(s1_note_e3),
      Number(s1_note_e4)
    );
    const min = Math.min(
      Number(s1_note_e1),
      Number(s1_note_e2),
      Number(s1_note_e3),
      Number(s1_note_e4)
    );
    routation[i][name] = value;

    routation[i]["s1_note_e"] = ((sum - max - min) / 2).toFixed(2);;

    setObjectCompetitiontable({ ...ObjectCompetitiontable, [i]: routation[i] });
    console.log(ObjectCompetitiontable);
  };

  const calculateNoteEs2 = (i, name, value) => {
    var s2_note_e1 = routation[i].s2_note_e1 ? routation[i].s2_note_e1 : 0;
    var s2_note_e2 = routation[i].s2_note_e2 ? routation[i].s2_note_e2 : 0;
    var s2_note_e3 = routation[i].s2_note_e3 ? routation[i].s2_note_e3 : 0;
    var s2_note_e4 = routation[i].s2_note_e4 ? routation[i].s2_note_e4 : 0;

    if(name=="s2_note_e1"){
      s2_note_e1=value;
    }
    else if(name=="s2_note_e2"){
      s2_note_e2=value;
    }
    else if(name=="s2_note_e3"){
      s2_note_e3=value;
    }
    else  if(name=="s2_note_e4"){
      s2_note_e4=value;
    }
    const sum =
      Number(s2_note_e1) +
      Number(s2_note_e2) +
      Number(s2_note_e3) +
      Number(s2_note_e4);
    const max = Math.max(
      Number(s2_note_e1),
      Number(s2_note_e2),
      Number(s2_note_e3),
      Number(s2_note_e4)
    );
    const min = Math.min(
      Number(s2_note_e1),
      Number(s2_note_e2),
      Number(s2_note_e3),
      Number(s2_note_e4)
    );
    routation[i][name] = value;

    routation[i]["s2_note_e"] = ((sum - max - min) / 2).toFixed(2);;

    setObjectCompetitiontable({ ...ObjectCompetitiontable, [i]: routation[i] });
    console.log(ObjectCompetitiontable);
  };

  const CalculS1 = (i, name, value) => {
    var s1_noteE = routation[i].s1_note_e ? routation[i].s1_note_e : 0;
    var s1_noteD = routation[i].s1_note_d ? routation[i].s1_note_d : 0;
    var s1_penalite = routation[i].s1_note_penalite? routation[i].s1_penalite : 0;

    if(name=="s1_noteE"){
      s1_noteE=value;
    }
    else if(name=="s1_noteD"){
      s1_noteD=value;
    }
    else if(name=="s1_penalite"){
      s1_penalite=value;
    }
    
    routation[i][name] = value;
    routation[i]["s1"] =(Number(s1_noteE) + Number(s1_noteD) - Number(s1_penalite)).toFixed(2);;
    setObjectCompetitiontable({ ...ObjectCompetitiontable, [i]: routation[i] });
  
  };

  const CalculS2 = (i, name, value) => {
    var s2_noteE = routation[i].s2_note_e ? routation[i].s2_note_e : 0;
    var s2_noteD = routation[i].s2_note_d ? routation[i].s2_note_d : 0;
    var s2_penalite = routation[i].s2_note_penalite? routation[i].s2_penalite : 0;
    const s1 = routation[i].s1 ? routation[i].s1 : 0;
    if(name=="s2_noteE"){
      s2_noteE=value;
    }
    else if(name=="s2_noteD"){
      s2_noteD=value;
    }
    else if(name=="s2_penalite"){
      s2_penalite=value;
    }

    routation[i][name] = value;
    
    routation[i]["s2"] = (Number(s2_noteE) + Number(s2_noteD) - Number(s2_penalite)).toFixed(2);
    setObjectCompetitiontable({ ...ObjectCompetitiontable, [i]: routation[i] });
    routation[i]["score_finale"] = ((Number(s1) + Number(routation[i]["s2"] )) / 2).toFixed(2);
    setObjectCompetitiontable({ ...ObjectCompetitiontable, [i]: routation[i] });
    
  };
  const CalculScorefinal = (i, name, value) => {
    var s1 = routation[i].s1 ? routation[i].s1 : 0;
    var s2 = routation[i].s2 ? routation[i].s2 : 0;
    if(name=="s1"){
      s1=value;
    }
    else if(name=="s2"){
      s2=value;
    }
     
    routation[i][name] = value;
    routation[i]["score_finale"] =((Number(s1) + Number(s2)) / 2).toFixed(2)
    setObjectCompetitiontable({ ...ObjectCompetitiontable, [i]: routation[i] });
  };
  return (
    <div>
      <div className id="home">
        <div className="tm-product-table-container">
          {role !== "table" ? (
              <table ref={tableRef} className="table table-hover tm-table-small tm-product-table">
              <thead>
                <tr >
             
                  <th align="center">Order</th>
                  <th align="center">Athlète </th>
                  <th align="center">Equipe </th>
                  <th align="center">Agree </th>
                  <th align="center"> </th>
                  {[...Array(4)].map((_, index) => (
                    <th align="center">E {index + 1} </th>
                  ))}
                  <th align="center" >E </th>
                  <th align="center">D </th>
                  <th align="center">Pénalite </th>
                  <th align="center">Score final </th>
                </tr>
              </thead>
              <tbody>
                {routation.map((item, index) => (
                  <tr  >
                    <td className="tm-product-name">{item.ordre}</td>
                    <td className="tm-product-name">{item.user}</td>
                    <td className="tm-product-name">{item.equipe}</td>
                    <td className="tm-product-name">{item.agree}</td>
                    <td className="tm-product-name">
                      <img src={item.logo} alt="Logo" className="logo-image" />
                    </td>

                    <td>
                    <input
                        className="form-control validate my-input"
                        type="number" min="0" max="10"
                        style={{ width: "80px" }}    
                        name={`note_e1` + index}
                        
                        readOnly={item.inresultat === 1}
                        value={item.res.length > 0 ? item.res[0].note_e1 : undefined}
                        onChange={(e) => {
                         
                          calculateNoteE(index, "note_e1",  e.target.value);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        className="form-control validate my-input"
                        type="number" min="0" max="10"
                        style={{ width: "80px" }}
                        name={`note_e1` + index}
                        value={item.res.length > 0 ? item.res[0].note_e2 : undefined}

                        readOnly={item.inresultat === 1}
                        onChange={(e) => {
                          calculateNoteE(index, "note_e2", e.target.value);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        className="form-control validate my-input"
                        type="number" min="0" max="10"
                        style={{ width: "80px" }}
                        value={item.res.length > 0 ? item.res[0].note_e3 : undefined}

                        readOnly={item.inresultat === 1}
                        onChange={(e) => {
                          calculateNoteE(index, "note_e3", e.target.value);
                        }}
                      />
                    </td>

                    <td>
                      <input
                        className="form-control validate my-input"
                        type="number" min="0" max="10"
                        style={{ width: "80px" }}
                        value={item.res.length > 0 ? item.res[0].note_e4 : undefined}

                        readOnly={item.inresultat === 1}
                        onChange={(e) => {
                          calculateNoteE(index, "note_e4", e.target.value);
                        }}
                      />
                    </td>
                    <td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          style={{ width: "80px" }}
                          readOnly
                          value={item.res.length > 0 ? item.res[0].note_e : ObjectCompetition[index]?.note_e}

                          name={`note_e` + index}
                          onChange={(e) => {
                            calculatescorefinale(
                              index,
                              "note_e",
                              e.target.value
                            );
                          }}
                        />{" "}
                      </td>
                    </td>
                    <td>
                      <input
                        style={{ width: "80px" }}
                        className="form-control validate my-input"
                        type="number"
                        value={item.res.length > 0 ? item.res[0].note_d : undefined}

                        readOnly={item.inresultat === 1}
                        onChange={(e) => {
                          calculatescorefinale(index, "note_d", e.target.value);
                        }}
                      />
                    </td>
                    <td>
                      <input
                      style={{ width: "80px" }}
                        className="form-control validate my-input"
                        type="number"
                        value={item.res.length > 0 ? item.res[0].penalite : undefined}

                        readOnly={item.inresultat === 1}
                        onChange={(e) => {
                          calculatescorefinale(
                            index,
                            "penalite",
                            e.target.value
                          );
                        }}
                      />
                    </td>
                    <td>
                      <input
                      style={{ width: "80px" }}
                        className="form-control validate my-input"
                        type="number"
                        step="0.01"
                        readOnly 
                        value={item.res.length > 0 ? item.res[0].score_finale : ObjectCompetition[index]?.score_Finale}

                        onChange={(e) => {
                          calculatescorefinale(
                            index,
                            "score_Finale",
                            e.target.value
                          );
                        }}
                      />
                    </td>
                    { item.inresultat === 0 &&(
                     <td>
                     <button onClick={() => handleSubmit(index)} hidden={item.submitting}>
                       {item.submitting ? "Submitting..." : "Valider"}
                     </button>
                   </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}


          
          {role === "table" ? (
            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 tm-block-col">
               <table ref={tableRef} className="table table-hover tm-table-small tm-product-table">
              <thead>
                  <tr>
                   
                    <th>Order</th>
                    <th>User </th>
                    <th>Agree </th>
                    <th>Equipe </th>
                    {[...Array(4)].map((_, index) => (
                      <th>S1 E{index + 1} </th>
                    ))}
                    <th>S1 E </th>
                    <th>S1 D </th>
                    <th>S1 Penalite </th>
                    <th>S1 </th>
                    {[...Array(4)].map((_, index) => (
                      <th>S2 E{index + 1} </th>
                    ))}
                    <th>S2 E </th>
                    <th>S2 D </th>
                    <th>S2 Penalite </th>
                    <th>S2 </th>
                    <th>Score final </th>
                  </tr>
                </thead>
                <tbody>
                  {routation.map((item, index) => (
                    <tr>
                      <td className="tm-product-name">{item.ordre}</td>
                      <td className="tm-product-name">{item.user}</td>
                      <td className="tm-product-name">{item.agree}</td>
                      <td className="tm-product-name">{item.equipe}</td>
                     

                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly={item.inresultat === 1}
                          name={`note_e1` + index}
                          value={item.res.length > 0 ? item.res[0].s1_note_e1 : undefined}
                          style={{ width: "80px" }}
                          onChange={(e) => {
                            calculateNoteE2( index, "s1_note_e1", e.target.value);}}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly={item.inresultat === 1}
                          style={{ width: "80px" }}
                          name={`note_e1` + index}
                          value={item.res.length > 0 ? item.res[0].s1_note_e2 : undefined}

                          onChange={(e) => {
                            calculateNoteE2(  index,"s1_note_e2", e.target.value  );}}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly={item.inresultat === 1}
                          value={item.res.length > 0 ? item.res[0].s1_note_e3 : undefined}

                          style={{ width: "80px" }}
                          onChange={(e) => {
                            calculateNoteE2( index, "s1_note_e3",  e.target.value ); }}
                        />
                      </td>

                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly={item.inresultat === 1}
                          value={item.res.length > 0 ? item.res[0].s1_note_e4 : undefined}

                          style={{ width: "80px" }}
                          onChange={(e) => {
                            calculateNoteE2(
                              index,
                              "s1_note_e4",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td>
                        <td>
                          <input
                            className="form-control validate my-input"
                            type="number"
                            style={{ width: "80px" }}
                            readOnly
                            value={item.res.length > 0 ? item.res[0].s1_note_e : ObjectCompetitiontable[index]?.s1_note_e}

                            name={`note_e` + index}
                            onChange={(e) => {
                              CalculS1(index, "s1_note_e", e.target.value);
                            }}
                          />{" "}
                        </td>
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly={item.inresultat === 1}
                          style={{ width: "80px" }}
                          value={item.res.length > 0 ? item.res[0].s1_note_d : undefined}
                          onChange={(e) => {
                            CalculS1(index, "s1_note_d", e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          value={item.res.length > 0 ? item.res[0].s1_penalite : undefined}

                          readOnly={item.inresultat === 1}
                          style={{ width: "80px" }}
                          onChange={(e) => {
                            CalculS1(index, "s1_penalite", e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly
                          value={item.res.length > 0 ? item.res[0].s1 : ObjectCompetitiontable[index]?.s1}
                          style={{ width: "80px" }}

                          onChange={(e) => {
                            CalculScorefinal(index, "s1", e.target.value);
                          }}
                        />
                      </td>

                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly={item.inresultat === 1}
                          style={{ width: "80px" }}
                          value={item.res.length > 0 ? item.res[0].s2_note_e1 : undefined}

                          name={`note_e1` + index}
                          onChange={(e) => {calculateNoteEs2(index, "s2_note_e1", e.target.value    ); }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          style={{ width: "80px" }}
                          type="number"
                          readOnly={item.inresultat === 1}
                          name={`note_e1` + index}
                          value={item.res.length > 0 ? item.res[0].s2_note_e2 : undefined}

                          onChange={(e) => { calculateNoteEs2( index,"s2_note_e2", e.target.value  );}}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly={item.inresultat === 1}
                          value={item.res.length > 0 ? item.res[0].s2_note_e3 : undefined}

                          style={{ width: "80px" }}
                          onChange={(e) => {  calculateNoteEs2( index,"s2_note_e3", e.target.value); }}
                        />
                      </td>

                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          value={item.res.length > 0 ? item.res[0].s2_note_e4 : undefined}

                          readOnly={item.inresultat === 1}
                          style={{ width: "80px" }}
                          onChange={(e) => {  calculateNoteEs2(index,"s2_note_e4",e.target.value  );
                          }}
                        />
                      </td>
                      <td>
                        <td>
                          <input
                            className="form-control validate my-input"
                            type="number"
                            style={{ width: "80px" }}
                            readOnly
                          value={item.res.length > 0 ? item.res[0].s2_note_e : ObjectCompetitiontable[index]?.s2_note_e}
                            name={`note_e` + index}
                            onChange={(e) => {
                              CalculS2(index, "s2_note_e", e.target.value);
                            }}
                          />{" "}
                        </td>
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly={item.inresultat === 1}
                          value={item.res.length > 0 ? item.res[0].s2_note_d : undefined}

                          style={{ width: "80px" }}
                          onChange={(e) => {
                            CalculS2(index, "s2_note_d", e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          value={item.res.length > 0 ? item.res[0].s2_penalite : undefined}

                          readOnly={item.inresultat === 1}
                          style={{ width: "80px" }}
                          onChange={(e) => {
                            CalculS2(index, "s2_penalite", e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly
                          value={item.res.length > 0 ? item.res[0].s2 : ObjectCompetitiontable[index]?.s2}
                          style={{ width: "80px" }}
                          onChange={(e) => {
                            CalculScorefinal(index, "s2", e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control validate my-input"
                          type="number"
                          readOnly
                          value={item.res.length > 0 ? item.res[0].score_finale : ObjectCompetitiontable[index]?.score_finale}

                          style={{ width: "80px" }}
                          step="0.01"
                          onChange={(e) => {
                            CalculScorefinal(index, "score_finale", e.target.value);
                          }}
                        />
                      </td>
                      {  item.inresultat === 0 &&(
                      <td>
                        <button onClick={() => handleSubmit(index)} hidden={item.submitting}>
                       {item.submitting ? "Submitting..." : "Valider"}
                     </button>
                      </td>
                        )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
        {/* table container */}
        
      </div>
    </div>
  );
}
