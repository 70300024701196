import React from 'react'
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify'

import {formulaire}  from '../Services/Formulaire/FormulaireApi'
export default function Formulaire() {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
     
        const repformulaire = await formulaire(data); 
        console.log(repformulaire.data);
        if(repformulaire?.status === 200 ){
            window.location.reload();

        }
        else{
            toast(repformulaire.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                            })
        }

    };

  return (

          <div >
            <div>
              <form action className="tm-edit-product-form" onSubmit={handleSubmit(onSubmit)}>
               <select className="custom-select tm-select-accounts" {...register('type', { required: true })}>
                    <option value="">Select an option</option>
                    <option value="bar">bar</option>
                    <option value="poutre">poutre</option>
                    <option value="sol">sol</option>
                    <option value="table">table</option>
                  </select>
                <div className="form-group mb-3">
                  <label htmlFor="user_code">user_code</label>
                  <input type="number"   {...register('user_code', { required: true })} className="form-control validate my-input" required />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="rotation_code">rotation_code</label>
                  <input className="form-control validate"   type="number"
                
                  {...register('rotation_code', { required: true })}/>
                </div>
                {[...Array(4)].map((_, index) => (
           <div className="form-group mb-3"  key={index}>
           <label htmlFor={`note_e${index + 1}`}>note_e{index + 1}</label>
           <input className="form-control validate my-input"   type="number"
         
         {...register(`note_e${index + 1}`, { required: true })}/>
         </div>
      ))}
                <div className="form-group mb-3">
                  <label htmlFor="note_e">note_e</label>
                  <input className="form-control validate my-input"   type="number"
             
                  {...register('note_e', { required: true })}/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="note_d">note_d</label>
                  <input className="form-control validate my-input"   type="number"
                 
                  {...register('note_d', { required: true })}/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="penalite">penalite</label>
                  <input className="form-control validate my-input"   type="number"
            
                  {...register('penalite', { required: true })}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="score_finale">score_finale</label>
                  <input className="form-control validate my-input"   type="number"
              step="0.01" 
                  {...register('score_finale', { required: true })}/>
                </div>
          
           
            <div className="col-12">
              <button type="submit" className="btn btn-primary btn-block text-uppercase">Ajouter</button>
            </div>
          
    
          </form></div></div>
  
  )
}
