import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { getbyscore } from '../Services/GetScore/GetbyScore';
export default function Poutre() {
  const navigate = useNavigate();

  const [poutre, setPoutre] = useState([]);
  let { type } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      
      try {
        let response;
        
          response = await getbyscore(type);
        

        setPoutre(response.data);
      } catch (error) {
        console.error('Error fetching Poutre data:', error);
      }
    };

    fetchData();
  }, [type]);

  return (
    <div className='pageAffichage'>

    <div className="header">
      <div  className = "iconright">
    
        <img src="/image/logo_FTG.jpg" className="mT" width="200" height="200" alt="Logo" />
    
      </div>
      <div className='center'>
      <div className="Titre1">
        <h2>البطولة الوطنية للجمباز الفني إناث  </h2> 
        <h2> 2023-2022 نهائيات الفرق والفردي العام موسم </h2>
      </div>
      <div className='icon'>
      {type === "sol" ? (
        <img src="/image/sol.jpg" className="logo-image mT"  alt="Logo" />
      ) : null}
      {type === "poutre" ? (
        <img src="/image/poutre.jpg" className="logo-image mT" alt="Logo" />
      ) : null}
      {type === "table" ? (
        <img src="/image/table.jpg" className="logo-image mT" alt="Logo" />
      ) : null}
      {type === "bar" ? (
        <img src="/image/bar.jpg" className="logo-image mT" alt="Logo" />
      ) : null}
      </div>
      
      </div>
      <div  className = "iconleft">
      <img src="/image/Tunisia-Flag-Transparent-Background.png" width="300" height="250" className="mT" alt="Logo" />
    </div>
      </div>
     
    
    <div >
    {type !== "table"?(
           <table className="table">
           <thead className="thead">
             <tr className="tr">
             <th  className="th"><h5> Athlelte </h5></th>
             <th  className="th"><h5> Equipe</h5></th>
             <th  className="th"> </th>
               <th  className="th"><h5> Note E</h5></th>
               <th className="th"> <h5>Note D</h5></th>
               <th className="th"><h5> Pénalite</h5></th>
               <th className="th"><h5> Note Finale</h5></th>
             </tr>
           </thead>
           
           <tbody>
           {type !== "table" && poutre.map((item) => (
               <tr className="tr">
               <td className="tm-product-name td " >{item.user}</td>
               <td className="tm-product-name td " >{item.equipe}</td>
               <td className="tm-product-name td " > <img src={item.logo} alt="Logo" className="logo-image" /></td>
                <td className="tm-product-name td " ><h2>{item.note_e}</h2></td>
                 <td className="tm-product-name td "><h2>{item.note_d}</h2></td>
                 <td className="tm-product-name td "><h2>{item.penalite}</h2></td>
                 <td className="tm-product-name td "><h2>{item.score_finale}</h2></td>
               </tr>
              
              ))}
           </tbody>    
          
    
         </table>
          ):null}
        
        
{type === "table"?(
         <table className="table">
          
            <thead className="thead">
              <tr className="tr">
              <th  className="th"><h4> Athlelte</h4></th>
             <th  className="th"><h4> Equipe</h4></th>
             <th  className="th">  </th>
             <th  className="th"> </th>
                <th  className="th"><h4> Note E</h4></th>
                <th className="th"><h4> Note D</h4></th>
                <th className="th"><h4> Pénalite</h4></th>
                <th className="th"><h4> Note Final</h4></th>
              </tr>
            </thead>
             
       {type === "table" && poutre.map((item) => (     
       <tbody>
       <tr className="tr">
              <td className="tm-product-name td " >{item.user}</td>
               <td className="tm-product-name td " >{item.equipe}</td>
               <td className="tm-product-name td " > <img src={item.logo} alt="Logo" className="logo-image" /></td>
               
         <td className="tm-product-name td"><h2>S1</h2></td>
         <td className="tm-product-name td"><h2>{item.s1_note_e}</h2></td>
         <td className="tm-product-name td"><h2>{item.s1_note_d}</h2></td>
         <td className="tm-product-name td"><h2>{item.s1_penalite}</h2></td>
         <td className="tm-product-name td" rowspan="2"><h2>{item.score_finale}</h2></td>
       </tr>
       <tr className="tr">
       <td className="tm-product-name td " >{item.user}</td>
               <td className="tm-product-name td " >{item.equipe}</td>
               <td className="tm-product-name td " > <img src={item.logo} alt="Logo" className="logo-image" /></td>
              
    
         <td className="tm-product-name td"><h2>S2</h2></td>
         <td className="tm-product-name td"><h2>{item.s2_note_e}</h2></td>
         <td className="tm-product-name td"><h2>{item.s2_note_d}</h2></td>
         <td className="tm-product-name td"><h2>{item.s2_penalite}</h2></td>
       </tr>
     </tbody> 
     ))} 
      </table> 
     ):null}
           
    </div>
    
        </div>
      

  );
}
