import axios from "axios";
/**
 * Authentification Web Service 
 * @returns 
 */
export async function login (data){
    try{
        const response = await axios.post(`https://api.competition.cleges.com/api/v1/login`,data,{
            IsEssential: true
          });
          return response;
    }catch(error){
        return error?.response
    }
}   
