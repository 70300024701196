import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import {login}  from "../Services/Login/LoginApi"
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Login() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [err , seterr]=useState(false); 
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const rep = await login(data); 
        if(rep?.data?.status === true){
          Cookies.set('token',rep.data.user.remember_token);
          Cookies.set('role',rep.data.user.role_code);
          Cookies.set('usercode',rep.data.user.code);
          console.log(rep.data.user);

        navigate(`/MainPage/${0}`);
        }
        else{
        
          seterr(true); 
        }

    };
  
    return (
    <div>
   <div className="container tm-mt-big tm-mb-big">
      <div className="row">
        <div className="col-12 mx-auto tm-login-col">
          <div className="tm-bg-primary-dark tm-block tm-block-h-auto">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="tm-block-title mb-4"> Login</h2>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)} className="tm-login-form">
                  <div className="form-group">
                    <label htmlFor="username">Email</label>
                    <input type="text"  className="form-control validate"  {...register("email", { required: true })}  />
                    {errors.email && <span className="error">Ce champ est requis</span>}
                   
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="password">Password</label>
                    <input  type="password" className="form-control validate" {...register("password", { required: true })}/>
                    {errors.password && <span className="error">Ce champ est requis</span>}
                  </div>
                  {err&& <span className="error">Données invalide </span>}
                  <div className="form-group mt-4">
                    <button type="submit" className="btn btn-primary btn-block text-uppercase">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
   
    
    );
  }
  

  