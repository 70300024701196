import axios from "axios";

export async function getbyscore(type) { 

     try {
         const response = await axios.get(`https://api.competition.cleges.com/api/v1/competitions/getAffichageByScore/${type}`)
         return response;
 
     }catch(error){
         return error?.response
     }
   } 
